export const setupMessageListener = (setIsOverlayVisible) => {
  const handleMessage = (event) => {
    switch (event.data.type) {
      case "showCloseButton":
        setIsOverlayVisible(true);
        break;
      case "hideCloseButton":
        setIsOverlayVisible(false);
        break;
      default:
        break;
    }
  };

  window.addEventListener("message", handleMessage);

  return () => {
    window.removeEventListener("message", handleMessage);
  };
};

export const notifyLanguageChange = (language, iframeRef) => {
  try {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      // Add origin check
      const targetOrigin = new URL(iframeRef.current.src).origin;
      iframeRef.current.contentWindow.postMessage(
        { type: "LANGUAGE", language },
        targetOrigin // Use specific origin instead of "*"
      );
    }
  } catch (error) {
    console.warn("Language notification failed:", error);
    // Prevent error from bubbling up
  }
};

export const notifyThemeChange = (theme, iframeRef) => {
  try {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      const targetOrigin = new URL(iframeRef.current.src).origin;
      iframeRef.current.contentWindow.postMessage(
        { type: "THEME", theme },
        targetOrigin
      );
    }
  } catch (error) {
    console.warn("Theme notification failed:", error);
    // Prevent error from bubbling up
  }
};
