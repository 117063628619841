// src/App.js
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./App.scss";
import HomePage from "./HomePage";
import Login from "./Login";
import ErrorPopup from "./components/ErrorPopup";
import Footer from "./components/Footer";
import Header from "./components/Header";
import HelmetComponent from "./components/Helmet";
import {
  checkUserLoggedIn,
  logout,
  setEmail,
  setWallet,
} from "./features/auth/authSlice";
import {
  bridges,
  deselectBridge,
  selectBridge,
} from "./features/bridge/bridgeSlice";
import AboutUs from "./components/AboutUs";
import { setView } from "./features/view/viewSlice";
import { getQueryParameter } from "./utils/helpers"; // Import helper function
import {
  setupMessageListener,
  notifyLanguageChange,
  notifyThemeChange, // Added notifyThemeChange
} from "./utils/messageHandler";

const App = () => {
  const dispatch = useDispatch();
  const { user, status, wallet } = useSelector((state) => state.auth);
  const selectedBridge = useSelector((state) => state.bridge.selectedBridge);
  const theme = useSelector((state) => state.theme);
  const currentView = useSelector((state) => state.view.currentView); // Get current view from Redux
  const selectedLanguage = useSelector((state) => state.terms.language);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const iframeRef = useRef(null);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const handleCloseBridge = () => {
    dispatch(deselectBridge());
    dispatch(setView("home"));
  };

  useEffect(() => {
    dispatch(checkUserLoggedIn());
  }, [dispatch]);

  useEffect(() => {
    setIsLoggedIn(!!user); // Convert user object to boolean
  }, [user]);

  useEffect(() => {
    if (user && !wallet) {
      dispatch(logout());
    }
  }, [wallet, user, dispatch]);

  useEffect(() => {
    // Check for the pr parameter and select the bridge accordingly
    const prParam = getQueryParameter("pr");
    const tabParam = getQueryParameter("tab");
    const walletAddressParam = getQueryParameter("walletAddress");
    const emailParam = getQueryParameter("email");

    if (prParam === "pLw0L") {
      dispatch(selectBridge("Playnance Swap Bridge Front")); // Automatically select Sell USDP bridge

      // Pre-fill wallet and email fields if present
      if (walletAddressParam) {
        dispatch(setWallet(walletAddressParam));
      }
      if (emailParam) {
        dispatch(setEmail(emailParam));
      }
    } else if (tabParam === "sell") {
      dispatch(selectBridge("Playnance Swap Bridge Front")); // Open the sell tab by default
    }
  }, [dispatch]);

  useEffect(() => {
    const cleanup = setupMessageListener(setIsOverlayVisible);
    return cleanup;
  }, []);

  useEffect(() => {
    notifyLanguageChange(selectedLanguage, iframeRef);
  }, [selectedLanguage]);

  useEffect(() => {
    notifyThemeChange(theme, iframeRef); // Notify theme change
  }, [theme]);

  const renderContent = () => {
    switch (currentView) {
      case "about":
        return <AboutUs />;
      case "home":
        return <HomePage />;
      default:
        return <HomePage />;
    }
  };

  const buildIframeSrc = () => {
    const bridgeUrl = bridges[selectedBridge];

    // Extract existing parameters from the URL
    const params = {
      wa: wallet,
      appDomain: window.location.host,
      userMagicAddress: user?.publicAddress,
      pr: getQueryParameter("pr"), // Include pr parameter if it exists
      // lang: selectedLanguage, // Add the selected language here
    };

    // Create a query string manually, filtering out any undefined values
    const queryString = Object.entries(params)
      .filter(([key, value]) => value) // Filter out undefined or null values
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    return `${bridgeUrl}/?${queryString}`;
  };

  if (status === "loading") {
    return (
      <div className={`App ${theme}`}>
        <HelmetComponent />
        <Header />
        <div className="loading">
          <div className="spinner"></div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className={`App ${theme}`}>
      <HelmetComponent />
      <Header />
      <ErrorPopup />
      {!selectedBridge && renderContent()}
      {selectedBridge && !isLoggedIn && <Login />}
      {selectedBridge && isLoggedIn && user && (
        <div className="bridge-iframe">
          <CloseButton
            isOverlayVisible={isOverlayVisible}
            onClick={handleCloseBridge}
          />
          <iframe
            ref={iframeRef}
            src={buildIframeSrc()}
            onLoad={() => {
              try {
                // Wait for iframe to fully load
                setTimeout(() => {
                  notifyLanguageChange(selectedLanguage, iframeRef);
                  notifyThemeChange(theme, iframeRef);
                }, 100);
              } catch (error) {
                console.warn("iframe onLoad handler failed:", error);
              }
            }}
            allow="clipboard-read; clipboard-write"
            title={selectedBridge}
            width="100%"
            height="100%"
            frameBorder="0"
          />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default App;

const CloseButton = ({ isOverlayVisible, onClick }) => {
  return (
    <button
      className={`close-button ${isOverlayVisible ? "overlay-visible" : ""}`}
      onClick={onClick}
    />
  );
};
