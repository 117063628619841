// src/components/AboutUs.js
import React from "react";
import { useSelector } from "react-redux";
import { selectTerms } from "../features/terms/termsSlice";

const AboutUs = () => {
  const terms = useSelector(selectTerms);

  return (
    <div className="about-us">
      <section className="about-us__intro">
        <h1 className="about-us__title">{terms.aboutUsLabel}</h1>
        <p className="about-us__subtitle">{terms.aboutUsSubtitle}</p>
      </section>
      <section className="about-us__values">
        <h2 className="about-us__section-title">{terms.ourValuesLabel}</h2>
        <div className="about-us__values-list">
          <div className="about-us__value">
            <h3 className="about-us__value-title">{terms.innovationLabel}</h3>
            <p className="about-us__value-description">
              {terms.innovationDescription}
            </p>
          </div>
          <div className="about-us__value">
            <h3 className="about-us__value-title">{terms.integrityLabel}</h3>
            <p className="about-us__value-description">
              {terms.integrityDescription}
            </p>
          </div>
          <div className="about-us__value">
            <h3 className="about-us__value-title">
              {terms.customerFocusLabel}
            </h3>
            <p className="about-us__value-description">
              {terms.customerFocusDescription}
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
