// src/features/bridge/bridgeSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedBridge: null,
  bridges: {
    "Playnance Bridge Front": "https://bridge.playnance.com",
    // "Playnance Bridge Front": "https://widget.coinsexchange.com",
    "Playnance Swap Bridge Front": "https://rb-app.coinsexchange.com",
  },
  /* bridges: {
    // Test URLs
    "Playnance Bridge Front": "http://localhost:3000",
    "Playnance Swap Bridge Front": "http://localhost:3001",
  }, */
  /* bridges: {
    // Test URLs
    "Playnance Bridge Front": "https://orkon.loca.lt",
    "Playnance Swap Bridge Front": "https://orkon1.loca.lt",
  }, */
};

const bridgeSlice = createSlice({
  name: "bridge",
  initialState,
  reducers: {
    selectBridge: (state, action) => {
      state.selectedBridge = action.payload;
    },
    deselectBridge: (state) => {
      state.selectedBridge = null;
    },
  },
});

export const { selectBridge, deselectBridge } = bridgeSlice.actions;
export const bridges = initialState.bridges; // Export bridges object
export default bridgeSlice.reducer;
