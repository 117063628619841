// src/components/SideMenu.js
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deselectBridge, selectBridge } from "../features/bridge/bridgeSlice";
import { closeMenu, toggleMenu } from "../features/menu/menuSlice";
import { setView } from "../features/view/viewSlice";
import useIsMobile from "../hooks/useIsMobile";
import LanguageSelector from "./LanguageSelector";
import { selectTerms } from "../features/terms/termsSlice";

const SideMenu = () => {
  const dispatch = useDispatch();
  const { isOpen, isLoggedIn } = useSelector((state) => ({
    isOpen: state.menu.isOpen,
    isLoggedIn: state.auth.user,
  }));
  const isMobile = useIsMobile();
  const terms = useSelector(selectTerms);

  const handleButtonClick = useCallback(
    (bridge) => {
      dispatch(selectBridge(bridge));
      dispatch(toggleMenu());
    },
    [dispatch]
  );

  const handleAboutUsClick = useCallback(() => {
    dispatch(deselectBridge());
    dispatch(setView("about"));
    dispatch(toggleMenu());
  }, [dispatch]);

  const handleHomeClick = useCallback(() => {
    dispatch(deselectBridge());
    dispatch(setView("home"));
    dispatch(toggleMenu());
  }, [dispatch]);

  useEffect(() => {
    if (isMobile) {
      dispatch(closeMenu());
    }
  }, [isMobile, dispatch]);

  return (
    <div className={`side-menu ${isOpen ? "open" : ""}`}>
      {isMobile && (
        <div className="side-menu-top">
          <LanguageSelector />
        </div>
      )}
      <div className="side-menu-content">
        <button onClick={handleHomeClick}>
          {/* Home */}
          {terms.homeLabel}
        </button>
        <button onClick={handleAboutUsClick}>
          {/* About Us */}
          {terms.aboutUsLabel}
        </button>
        {isLoggedIn && (
          <>
            <button onClick={() => handleButtonClick("Playnance Bridge Front")}>
              {/* Buy USDP */}
              {terms.buyUSDPLabel}
            </button>
            <button
              onClick={() => handleButtonClick("Playnance Swap Bridge Front")}
            >
              {/* Sell USDP */}
              {terms.sellUSDPLabel}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default SideMenu;
