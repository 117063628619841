// src/Login.js
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  setEmail,
  setWallet,
  setWalletValidity,
} from "./features/auth/authSlice";
import { deselectBridge } from "./features/bridge/bridgeSlice"; // Import deselectBridge
import topLogo from "./Images/top_logo.png"; // Import the top logo
import footerLogo from "./Images/logo_com.png"; // Import the footer logo
import infoIcon from "./Images/info_icon.png"; // Import the info icon
import backIcon from "./Images/back_icon.png"; // Import the back icon
import {
  isIframe,
  getQueryParameter,
  shortenWalletAddress,
  isWebView,
} from "./utils/helpers"; // Import helper functions
import { selectTerms } from "./features/terms/termsSlice";
String.prototype.capitalize = function () {
  return this.replace(/\b\w/g, (c) => c.toUpperCase());
};

const Login = () => {
  const dispatch = useDispatch();
  const email = useSelector((state) => state.auth.email);
  const wallet = useSelector((state) => state.auth.wallet);
  const isWalletValid = useSelector((state) => state.auth.isWalletValid);
  const authStatus = useSelector((state) => state.auth.status);
  const selectedBridge = useSelector((state) => state.bridge.selectedBridge);
  const terms = useSelector(selectTerms);

  useEffect(() => {
    if (isIframe() || isWebView()) {
      const walletAddress = getQueryParameter("walletAddress");
      if (walletAddress) {
        dispatch(setWallet(walletAddress));
        dispatch(setWalletValidity(true));
      }
    }
  }, [dispatch]);

  const handleEmailChange = (e) => {
    dispatch(setEmail(e.target.value));
  };

  const handleWalletChange = (e) => {
    dispatch(setWallet(e.target.value));
  };

  const handleLogin = () => {
    if (email && (isWalletValid || isIframe() || isWebView())) {
      dispatch(login({ email, wallet }));
    }
  };

  const handleBack = () => {
    dispatch(deselectBridge());
  };

  const bridgeText =
    selectedBridge === "Playnance Bridge Front"
      ? /* "Buy USDP" */ terms.header_title_global_buy_usdp
      : /* "Sell USDP" */ terms.header_title_sell_usdp;
  const walletPlaceholder =
    selectedBridge === "Playnance Bridge Front"
      ? /* "Recipient Address" */ terms.page3_recipient_address_label
        ? terms.page3_recipient_address_label.capitalize()
        : terms.page3_recipient_address_label
      : /* "Sender Address" */ terms.senderAddressLabel
      ? terms.senderAddressLabel.capitalize()
      : terms.senderAddressLabel;

  const buttonStyle = {
    backgroundColor:
      selectedBridge === "Playnance Bridge Front" ? "#019eff" : "#fe9725",
    color: "#fff",
  };

  return (
    <div className="login">
      <div className="login-container">
        <header className="login-header">
          <button
            className="back-button"
            onClick={handleBack}
            style={{ backgroundImage: `url(${backIcon})` }}
          />
          <h1>{bridgeText}</h1>
          <div
            className="top-logo"
            style={{ backgroundImage: `url(${topLogo})` }}
          />
        </header>
        <h2>
          {/* Enter your details to log in and continue */}
          {terms.enter_details_to_log_in_and_continue}
        </h2>
        <div className="input-group">
          <label htmlFor="email">
            {/* Enter Your Email */}
            {terms.enter_your_email}
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            placeholder=/* "Email" */ {terms.email}
          />
        </div>
        {(!isIframe() || !isWebView()) && (
          <div className="input-group">
            <label htmlFor="wallet">{walletPlaceholder}</label>
            <div className="input-wrapper">
              <input
                type="text"
                id="wallet"
                value={shortenWalletAddress(wallet)}
                onChange={handleWalletChange}
                placeholder={walletPlaceholder}
              />
              {/* <img src={infoIcon} alt="info" className="info-icon" /> */}
            </div>
          </div>
        )}
        <button
          onClick={handleLogin}
          disabled={
            authStatus === "loading" ||
            (!isWalletValid && (!isIframe() || !isWebView()))
          }
          style={buttonStyle}
        >
          {authStatus === "loading"
            ? /* "Logging in..." */ terms.logging_in
            : /* "Login" */ terms.login}
        </button>
        <div className="error-container">
          {!isWalletValid && wallet && (!isIframe() || !isWebView()) && (
            <p className="error">
              {/* Invalid wallet address */}
              {terms.invalid_wallet_address}
            </p>
          )}
        </div>
        <footer className="login-footer">
          <div
            className="footer-logo"
            style={{ backgroundImage: `url(${footerLogo})` }}
          />
        </footer>
      </div>
    </div>
  );
};

export default Login;
