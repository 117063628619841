import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getLanguageTerms } from "../../utils/languageUtils";

// Async thunk to fetch terms based on language
export const fetchTerms = createAsyncThunk(
  "terms/fetchTerms",
  async (lang, thunkAPI) => {
    const response = await getLanguageTerms(lang);
    if (response) {
      return response;
    } else {
      return thunkAPI.rejectWithValue("Failed to fetch terms");
    }
  }
);

const termsSlice = createSlice({
  name: "terms",
  initialState: {
    terms: {},
    language: "en",
    status: "idle",
    error: null,
    isLoaded: false,
  },
  reducers: {
    setLanguage(state, action) {
      state.language = action.payload;
      // Optionally reset terms if needed
      // state.terms = {};
      // state.isLoaded = false;
    },
    setTerms: (state, action) => {
      state.terms = action.payload;
      state.isLoaded = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTerms.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTerms.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.terms = action.payload.reduce((acc, term) => {
          if (term.termKey && term.termValue) {
            acc[term.termKey] = term.termValue;
          }
          return acc;
        }, {});
        state.isLoaded = true;
      })
      .addCase(fetchTerms.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { setLanguage, setTerms } = termsSlice.actions;
export const selectTerms = (state) => state.terms.terms;
export const selectTermsLoaded = (state) => state.terms.isLoaded;

export default termsSlice.reducer;
