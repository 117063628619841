// src/utils/languageUtils.js
import axios from "axios";

const BASE_URL = "https://api.playblock.io/v1/config/terms/coinsexchange";

export const getLanguageTerms = async (lang = "en") => {
  try {
    const response = await axios.get(`${BASE_URL}?lang=${lang}`);
    // console.log(`Fetched language terms for '${lang}':`, response.data); // for debugging
    return response.data;
  } catch (error) {
    console.error(`Error fetching language terms for '${lang}':`, error);
    return null;
  }
};

export const processLanguageTerms = async (data, currentLangCode) => {
  const termsObject = data[currentLangCode];
  const termsArray = Object.entries(termsObject).map(
    ([termKey, termValue]) => ({
      termKey,
      termValue,
    })
  );

  if (termsArray && termsArray.length > 0) {
    return termsArray;
  } else {
    const defaultData = await getLanguageTerms("en");
    const defaultTermsObject = defaultData["en"];
    const defaultTermsArray = Object.entries(defaultTermsObject).map(
      ([termKey, termValue]) => ({
        termKey,
        termValue,
      })
    );
    return defaultTermsArray;
  }
};

const fakeResponse = {
  en: [
    // --- ConfirmationPopup.jsx ---
    {
      id: 1,
      lang: "en",
      termKey: "confirmation_popup_title",
      termValue: "Dear Customer",
    },
    {
      id: 2,
      lang: "en",
      termKey: "confirmation_popup_message",
      termValue:
        "Please note that you are buying USDT (tron) with Paybis, and it will be swapped automatically to USDP (Playblock) game tokens with Coinsexchange, for you to play. The swap can take a few minutes, and you will receive a confirmation mail by Coinsexchange.",
    },
    {
      id: 3,
      lang: "en",
      termKey: "confirmation_popup_continue_button",
      termValue: "OK, continue",
    },
    {
      id: 4,
      lang: "en",
      termKey: "confirmation_popup_close_button",
      termValue: "Close",
    },

    // --- ExchangeRate.jsx ---
    {
      id: 5,
      lang: "en",
      termKey: "input_group_you_send_label",
      termValue: "You send",
    },
    {
      id: 6,
      lang: "en",
      termKey: "exchange_rate_label",
      termValue: "Exchange rate: 1",
    },
    {
      id: 7,
      lang: "en",
      termKey: "exchange_fee_label",
      termValue: "Fee",
    },
    {
      id: 8,
      lang: "en",
      termKey: "funds_transferred_text",
      termValue: "Funds transferred immediately",
    },
    {
      id: 9,
      lang: "en",
      termKey: "exchange_continue_button",
      termValue: "Continue",
    },

    // --- Footer.jsx ---
    {
      id: 10,
      lang: "en",
      termKey: "footer_provided_by",
      termValue: "This service is provided by",
    },

    // --- Header.jsx ---
    {
      id: 11,
      lang: "en",
      termKey: "header_title_buy_usdp_0",
      termValue: "Top up with crypto",
    },
    {
      id: 12,
      lang: "en",
      termKey: "header_title_buy_usdp_1",
      termValue: "Amount",
    },
    {
      id: 13,
      lang: "en",
      termKey: "header_title_buy_usdp_2",
      termValue: "Recipient",
    },
    {
      id: 14,
      lang: "en",
      termKey: "header_title_buy_usdp_3",
      termValue: "Success",
    },
    {
      id: 15,
      lang: "en",
      termKey: "header_title_sell_usdp",
      termValue: "Sell USDP",
    },
    {
      id: 16,
      lang: "en",
      termKey: "header_title_global_buy_usdp",
      termValue: "Buy USDP",
    },

    // --- IframePopup.jsx ---
    {
      id: 17,
      lang: "en",
      termKey: "iframe_popup_close_button",
      termValue: "Close",
    },

    // --- InputGroup.jsx ---
    {
      id: 18,
      lang: "en",
      termKey: "input_group_you_send_label",
      termValue: "You send",
    },
    {
      id: 19,
      lang: "en",
      termKey: "input_group_enter_amount_placeholder",
      termValue: "Enter Amount",
    },
    {
      id: 20,
      lang: "en",
      termKey: "input_group_you_receive_label",
      termValue: "You receive (approximately) ≈",
    },

    // --- Page1.jsx ---
    {
      id: 21,
      lang: "en",
      termKey: "page1_bridge_title",
      termValue: "Bridge with 0% fees",
    },
    {
      id: 22,
      lang: "en",
      termKey: "page1_error_message",
      termValue: "There was an error",
    },
    {
      id: 23,
      lang: "en",
      termKey: "page1_loading",
      termValue: "Loading...",
    },

    // --- Page1Global.jsx ---
    {
      id: 24,
      lang: "en",
      termKey: "page1_global_buy_bank_transfer",
      termValue: "Buy with bank transfer",
    },
    {
      id: 25,
      lang: "en",
      termKey: "page1_global_buy_card",
      termValue: "Buy with credit/debit card",
    },
    {
      id: 26,
      lang: "en",
      termKey: "page1_global_buy_button_icons",
      termValue: "Credit/Debit Card Icons",
    },

    // --- Page3.jsx ---
    {
      id: 27,
      lang: "en",
      termKey: "page3_send_currency_label_1",
      termValue: "Send",
    },
    {
      id: 28,
      lang: "en",
      termKey: "page3_send_currency_label_2",
      termValue: "to this Address",
    },
    {
      id: 29,
      lang: "en",
      termKey: "page3_recipient_address_label",
      termValue: "Recipient address",
    },
    {
      id: 30,
      lang: "en",
      termKey: "page3_waiting_for_deposit",
      termValue: "Waiting for deposit",
    },

    // --- Page3Global.jsx ---
    {
      id: 31,
      lang: "en",
      termKey: "page3_global_send_usdp_label",
      termValue: "Send {symbol} to this Address",
    },
    {
      id: 32,
      lang: "en",
      termKey: "page3_global_recipient_address_label",
      termValue: "Recipient address",
    },
    {
      id: 33,
      lang: "en",
      termKey: "page3_global_waiting_for_deposit",
      termValue: "Waiting for deposit",
    },
    {
      id: 34,
      lang: "en",
      termKey: "page3_global_copy",
      termValue: "Copy address",
    },
    {
      id: 35,
      lang: "en",
      termKey: "page3_global_copy_success",
      termValue: "Copied",
    },

    // --- Page4.jsx ---
    {
      id: 36,
      lang: "en",
      termKey: "page4_transaction_arrived",
      termValue: "Transaction arrived",
    },
    {
      id: 37,
      lang: "en",
      termKey: "page4_exchanging",
      termValue: "Exchanging",
    },
    {
      id: 38,
      lang: "en",
      termKey: "page4_transaction_complete",
      termValue: "Transaction complete",
    },
    {
      id: 39,
      lang: "en",
      termKey: "page4_amount_sent",
      termValue: "Amount was sent to Your wallet",
    },
    {
      id: 40,
      lang: "en",
      termKey: "page4_continue_button_loading",
      termValue: "Transaction In Progress",
    },
    {
      id: 41,
      lang: "en",
      termKey: "page4_continue_button",
      termValue: "Transaction was successful",
    },

    // --- Page4Global.jsx ---
    {
      id: 42,
      lang: "en",
      termKey: "page4_global_transaction_in_progress",
      termValue: "Transaction in progress",
    },
    {
      id: 43,
      lang: "en",
      termKey: "page4_global_transaction_arrived",
      termValue: "Transaction arrived",
    },
    {
      id: 44,
      lang: "en",
      termKey: "page4_global_transaction_complete",
      termValue: "Transaction complete",
    },

    // --- QRContainer.jsx ---
    {
      id: 45,
      lang: "en",
      termKey: "qrcontainer_send_only_1",
      termValue: "Send",
    },
    {
      id: 46,
      lang: "en",
      termKey: "qrcontainer_send_only_2",
      termValue: "Only",
    },

    // --- StatusBar.jsx ---
    {
      id: 47,
      lang: "en",
      termKey: "statusbar_amount",
      termValue: "Amount",
    },
    {
      id: 48,
      lang: "en",
      termKey: "statusbar_recipient",
      termValue: "Recipient",
    },
    {
      id: 49,
      lang: "en",
      termKey: "statusbar_status",
      termValue: "Status",
    },

    // --- SwapTimer.jsx ---
    {
      id: 50,
      lang: "en",
      termKey: "swaptimer_swap_time_info",
      termValue: "Swap can take a few minutes.",
    },

    // --- CoinsExchange Project Specific Terms ---
    {
      id: 51,
      lang: "en",
      termKey: "logout",
      termValue: "Logout",
    },
    {
      id: 52,
      lang: "en",
      termKey: "selectBridge",
      termValue: "Select a Bridge",
    },
    {
      id: 53,
      lang: "en",
      termKey: "buyUSDPLabel",
      termValue: "Buy USDP",
    },
    {
      id: 54,
      lang: "en",
      termKey: "sellUSDPLabel",
      termValue: "Sell USDP",
    },
    {
      id: 55,
      lang: "en",
      termKey: "homeLabel",
      termValue: "Home",
    },
    {
      id: 56,
      lang: "en",
      termKey: "aboutUsLabel",
      termValue: "About Us",
    },
    {
      id: 57,
      lang: "en",
      termKey: "aboutUsSubtitle",
      termValue:
        "We are a leading company in the industry, committed to delivering the best services and products to our customers.",
    },
    {
      id: 58,
      lang: "en",
      termKey: "ourValuesLabel",
      termValue: "Our Values",
    },
    {
      id: 59,
      lang: "en",
      termKey: "innovationLabel",
      termValue: "Innovation",
    },
    {
      id: 60,
      lang: "en",
      termKey: "innovationDescription",
      termValue:
        "We are constantly innovating to meet the evolving needs of our customers and the industry.",
    },
    {
      id: 61,
      lang: "en",
      termKey: "integrityLabel",
      termValue: "Integrity",
    },
    {
      id: 62,
      lang: "en",
      termKey: "integrityDescription",
      termValue:
        "Integrity is at the core of our business. We believe in doing the right thing, always.",
    },
    {
      id: 63,
      lang: "en",
      termKey: "customerFocusLabel",
      termValue: "Customer Focus",
    },
    {
      id: 64,
      lang: "en",
      termKey: "customerFocusDescription",
      termValue:
        "Our customers are our top priority. We are dedicated to providing exceptional service and support.",
    },

    // --- Reverse Bridge Project Specific Terms ---
    {
      id: 65,
      lang: "en",
      termKey: "minWithdrawalText",
      termValue:
        "To support swap transaction fees, the minimum amount to swap is",
    },
    {
      id: 66,
      lang: "en",
      termKey: "networkFeesText",
      termValue: "network fees",
    },
    {
      id: 67,
      lang: "en",
      termKey: "networkLabel",
      termValue: "Network",
    },
    {
      id: 68,
      lang: "en",
      termKey: "senderAddressLabel",
      termValue: "Sender Address",
    },
    {
      id: 69,
      lang: "en",
      termKey: "receiverAddressLabel",
      termValue: "Receiver Address",
    },
    {
      id: 70,
      lang: "en",
      termKey: "invalidAddressText",
      termValue: "Invalid address",
    },
    {
      id: 71,
      lang: "en",
      termKey: "invalidText",
      termValue: "Please enter a valid address.",
    },
    {
      id: 72,
      lang: "en",
      termKey: "page2_swap_info_text",
      termValue:
        "Please make sure that you send the USDP from the wallet that you connected with. If you send from other wallets, the funds will be lost.",
    },
    {
      id: 73,
      lang: "en",
      termKey: "invalidAddressText_1",
      termValue: "Address status",
    },
    {
      id: 74,
      lang: "en",
      termKey: "invalidAddressText_2",
      termValue: "valid",
    },
    {
      id: 75,
      lang: "en",
      termKey: "invalidAddressText_3",
      termValue: "invalid",
    },
    {
      id: 76,
      lang: "en",
      termKey: "invalidAddressText_4",
      termValue: "on the",
    },

    // More CoinsExchange Project Specific Terms
    {
      id: 77,
      lang: "en",
      termKey: "page2_swap_info_text_1",
      termValue:
        "Please make sure that you send the USDP from the wallet that you connected with. If you send from other wallets, the funds will be lost.",
    },
    {
      id: 78,
      lang: "en",
      termKey: "enter_details_to_log_in_and_continue",
      termValue: "Enter your details to log in and continue",
    },
    {
      id: 79,
      lang: "en",
      termKey: "enter_your_email",
      termValue: "Enter Your Email",
    },
    {
      id: 80,
      lang: "en",
      termKey: "email",
      termValue: "Email",
    },
    {
      id: 81,
      lang: "en",
      termKey: "invalid_wallet_address",
      termValue: "Invalid wallet address",
    },
    {
      id: 82,
      lang: "en",
      termKey: "logging_in",
      termValue: "Logging in...",
    },
    {
      id: 83,
      lang: "en",
      termKey: "login",
      termValue: "Login",
    },
  ],

  ru: [
    // --- ConfirmationPopup.jsx ---
    {
      id: 1,
      lang: "ru",
      termKey: "confirmation_popup_title",
      termValue: "Уважаемый клиент",
    },
    {
      id: 2,
      lang: "ru",
      termKey: "confirmation_popup_message",
      termValue:
        "Обратите внимание, что вы покупаете USDT (tron) через Paybis, и он будет автоматически обменен на игровые токены USDP (Playblock) на Coinsexchange для игры. Обмен может занять несколько минут, и вы получите подтверждение на электронную почту от Coinsexchange.",
    },
    {
      id: 3,
      lang: "ru",
      termKey: "confirmation_popup_continue_button",
      termValue: "ОК, продолжить",
    },
    {
      id: 4,
      lang: "ru",
      termKey: "confirmation_popup_close_button",
      termValue: "Закрыть",
    },

    // --- ExchangeRate.jsx ---
    {
      id: 5,
      lang: "ru",
      termKey: "input_group_you_send_label",
      termValue: "Вы отправляете",
    },
    {
      id: 6,
      lang: "ru",
      termKey: "exchange_rate_label",
      termValue: "Обменный курс: 1",
    },
    {
      id: 7,
      lang: "ru",
      termKey: "exchange_fee_label",
      termValue: "Комиссия",
    },
    {
      id: 8,
      lang: "ru",
      termKey: "funds_transferred_text",
      termValue: "Средства переведены немедленно",
    },
    {
      id: 9,
      lang: "ru",
      termKey: "exchange_continue_button",
      termValue: "Продолжить",
    },

    // --- Footer.jsx ---
    {
      id: 10,
      lang: "ru",
      termKey: "footer_provided_by",
      termValue: "Эта услуга предоставляется",
    },

    // --- Header.jsx ---
    {
      id: 11,
      lang: "ru",
      termKey: "header_title_buy_usdp_0",
      termValue: "Пополнение криптовалютой",
    },
    {
      id: 12,
      lang: "ru",
      termKey: "header_title_buy_usdp_1",
      termValue: "Сумма",
    },
    {
      id: 13,
      lang: "ru",
      termKey: "header_title_buy_usdp_2",
      termValue: "Получатель",
    },
    {
      id: 14,
      lang: "ru",
      termKey: "header_title_buy_usdp_3",
      termValue: "Успех",
    },
    {
      id: 15,
      lang: "ru",
      termKey: "header_title_sell_usdp",
      termValue: "Продать USDP",
    },
    {
      id: 16,
      lang: "ru",
      termKey: "header_title_global_buy_usdp",
      termValue: "Купить USDP",
    },

    // --- IframePopup.jsx ---
    {
      id: 17,
      lang: "ru",
      termKey: "iframe_popup_close_button",
      termValue: "Закрыть",
    },

    // --- InputGroup.jsx ---
    {
      id: 18,
      lang: "ru",
      termKey: "input_group_you_send_label",
      termValue: "Вы отправляете",
    },
    {
      id: 19,
      lang: "ru",
      termKey: "input_group_enter_amount_placeholder",
      termValue: "Введите сумму",
    },
    {
      id: 20,
      lang: "ru",
      termKey: "input_group_you_receive_label",
      termValue: "Вы получите (приблизительно) ≈",
    },

    // --- Page1.jsx ---
    {
      id: 21,
      lang: "ru",
      termKey: "page1_bridge_title",
      termValue: "Мост с 0% комиссией",
    },
    {
      id: 22,
      lang: "ru",
      termKey: "page1_error_message",
      termValue: "Произошла ошибка",
    },
    {
      id: 23,
      lang: "ru",
      termKey: "page1_loading",
      termValue: "Загрузка...",
    },

    // --- Page1Global.jsx ---
    {
      id: 24,
      lang: "ru",
      termKey: "page1_global_buy_bank_transfer",
      termValue: "Купить с банковским переводом",
    },
    {
      id: 25,
      lang: "ru",
      termKey: "page1_global_buy_card",
      termValue: "Купить с кредитной/дебетовой картой",
    },
    {
      id: 26,
      lang: "ru",
      termKey: "page1_global_buy_button_icons",
      termValue: "Иконки кредитных/дебетовых карт",
    },

    // --- Page3.jsx ---
    {
      id: 27,
      lang: "ru",
      termKey: "page3_send_currency_label_1",
      termValue: "Отправить",
    },
    {
      id: 28,
      lang: "ru",
      termKey: "page3_send_currency_label_2",
      termValue: "на этот адрес",
    },
    {
      id: 29,
      lang: "ru",
      termKey: "page3_recipient_address_label",
      termValue: "Адрес получателя",
    },
    {
      id: 30,
      lang: "ru",
      termKey: "page3_waiting_for_deposit",
      termValue: "Ожидание депозита",
    },

    // --- Page3Global.jsx ---
    {
      id: 31,
      lang: "ru",
      termKey: "page3_global_send_usdp_label",
      termValue: "Отправьте {symbol} на этот адрес",
    },
    {
      id: 32,
      lang: "ru",
      termKey: "page3_global_recipient_address_label",
      termValue: "Адрес получателя",
    },
    {
      id: 33,
      lang: "ru",
      termKey: "page3_global_waiting_for_deposit",
      termValue: "Ожидание депозита",
    },
    {
      id: 34,
      lang: "ru",
      termKey: "page3_global_copy",
      termValue: "Скопировать адрес",
    },
    {
      id: 35,
      lang: "ru",
      termKey: "page3_global_copy_success",
      termValue: "Скопировано",
    },

    // --- Page4.jsx ---
    {
      id: 36,
      lang: "ru",
      termKey: "page4_transaction_arrived",
      termValue: "Транзакция прибыла",
    },
    {
      id: 37,
      lang: "ru",
      termKey: "page4_exchanging",
      termValue: "Обмен",
    },
    {
      id: 38,
      lang: "ru",
      termKey: "page4_transaction_complete",
      termValue: "Транзакция завершена",
    },
    {
      id: 39,
      lang: "ru",
      termKey: "page4_amount_sent",
      termValue: "Сумма отправлена на ваш кошелек",
    },
    {
      id: 40,
      lang: "ru",
      termKey: "page4_continue_button_loading",
      termValue: "Транзакция в процессе",
    },
    {
      id: 41,
      lang: "ru",
      termKey: "page4_continue_button",
      termValue: "Транзакция прошла успешно",
    },

    // --- Page4Global.jsx ---
    {
      id: 42,
      lang: "ru",
      termKey: "page4_global_transaction_in_progress",
      termValue: "Транзакция в процессе",
    },
    {
      id: 43,
      lang: "ru",
      termKey: "page4_global_transaction_arrived",
      termValue: "Транзакция прибыла",
    },
    {
      id: 44,
      lang: "ru",
      termKey: "page4_global_transaction_complete",
      termValue: "Транзакция завершена",
    },

    // --- QRContainer.jsx ---
    {
      id: 45,
      lang: "ru",
      termKey: "qrcontainer_send_only_1",
      termValue: "Отправить",
    },
    {
      id: 46,
      lang: "ru",
      termKey: "qrcontainer_send_only_2",
      termValue: "Только",
    },

    // --- StatusBar.jsx ---
    {
      id: 47,
      lang: "ru",
      termKey: "statusbar_amount",
      termValue: "Сумма",
    },
    {
      id: 48,
      lang: "ru",
      termKey: "statusbar_recipient",
      termValue: "Получатель",
    },
    {
      id: 49,
      lang: "ru",
      termKey: "statusbar_status",
      termValue: "Статус",
    },

    // --- SwapTimer.jsx ---
    {
      id: 50,
      lang: "ru",
      termKey: "swaptimer_swap_time_info",
      termValue: "Обмен может занять несколько минут.",
    },

    // --- CoinsExchange Project Specific Terms ---
    {
      id: 51,
      lang: "ru",
      termKey: "logout",
      termValue: "Выйти",
    },
    {
      id: 52,
      lang: "ru",
      termKey: "selectBridge",
      termValue: "Выберите мост",
    },
    {
      id: 53,
      lang: "ru",
      termKey: "buyUSDPLabel",
      termValue: "Купить USDP",
    },
    {
      id: 54,
      lang: "ru",
      termKey: "sellUSDPLabel",
      termValue: "Продать USDP",
    },
    {
      id: 55,
      lang: "ru",
      termKey: "homeLabel",
      termValue: "Главная",
    },
    {
      id: 56,
      lang: "ru",
      termKey: "aboutUsLabel",
      termValue: "О нас",
    },
    {
      id: 57,
      lang: "ru",
      termKey: "aboutUsSubtitle",
      termValue:
        "Мы ведущая компания в отрасли, обязаны предоставлять лучшие услуги и продукты нашим клиентам.",
    },
    {
      id: 58,
      lang: "ru",
      termKey: "ourValuesLabel",
      termValue: "Наши ценности",
    },
    {
      id: 59,
      lang: "ru",
      termKey: "innovationLabel",
      termValue: "Инновации",
    },
    {
      id: 60,
      lang: "ru",
      termKey: "innovationDescription",
      termValue:
        "Мы постоянно совершенствуемся, чтобы удовлетворить меняющиеся потребности наших клиентов и отрасли.",
    },
    {
      id: 61,
      lang: "ru",
      termKey: "integrityLabel",
      termValue: "Целостность",
    },
    {
      id: 62,
      lang: "ru",
      termKey: "integrityDescription",
      termValue:
        "Целостность является основой нашего бизнеса. Мы верим в то, чтобы всегда делать правильное.",
    },
    {
      id: 63,
      lang: "ru",
      termKey: "customerFocusLabel",
      termValue: "Ориентация на клиента",
    },
    {
      id: 64,
      lang: "ru",
      termKey: "customerFocusDescription",
      termValue:
        "Наши клиенты - наш главный приоритет. Мы стремимся предоставить исключительный сервис и поддержку.",
    },

    // --- Reverse Bridge Project Specific Terms ---
    {
      id: 65,
      lang: "ru",
      termKey: "minWithdrawalText",
      termValue:
        "Для поддержки комиссий за обмен минимальная сумма для обмена составляет",
    },
    {
      id: 66,
      lang: "ru",
      termKey: "networkFeesText",
      termValue: "сетевые комиссии",
    },
    {
      id: 67,
      lang: "ru",
      termKey: "networkLabel",
      termValue: "Сеть",
    },
    {
      id: 68,
      lang: "ru",
      termKey: "senderAddressLabel",
      termValue: "Адрес отправителя",
    },
    {
      id: 69,
      lang: "ru",
      termKey: "receiverAddressLabel",
      termValue: "Адрес получателя",
    },
    {
      id: 70,
      lang: "ru",
      termKey: "invalidAddressText",
      termValue: "Неверный адрес",
    },
    {
      id: 71,
      lang: "ru",
      termKey: "invalidText",
      termValue: "Пожалуйста, введите действительный адрес.",
    },
    {
      id: 72,
      lang: "ru",
      termKey: "page2_swap_info_text",
      termValue:
        "Пожалуйста, убедитесь, что вы отправляете USDP из кошелька, который вы подключили. Если вы отправите из других кошельков, средства будут потеряны.",
    },
    {
      id: 73,
      lang: "ru",
      termKey: "invalidAddressText_1",
      termValue: "Статус адреса",
    },
    {
      id: 74,
      lang: "ru",
      termKey: "invalidAddressText_2",
      termValue: "действительный",
    },
    {
      id: 75,
      lang: "ru",
      termKey: "invalidAddressText_3",
      termValue: "недействительный",
    },
    {
      id: 76,
      lang: "ru",
      termKey: "invalidAddressText_4",
      termValue: "на",
    },
    // More CoinsExchange Project Specific Terms
    {
      id: 77,
      lang: "ru",
      termKey: "page2_swap_info_text_1",
      termValue:
        "Пожалуйста, убедитесь, что вы отправляете USDP из кошелька, который вы подключили. Если вы отправите из других кошельков, средства будут потеряны.",
    },
    {
      id: 78,
      lang: "ru",
      termKey: "enter_details_to_log_in_and_continue",
      termValue: "Введите свои данные для входа и продолж continue",
    },
    {
      id: 79,
      lang: "ru",
      termKey: "enter_your_email",
      termValue: "Введите свой адрес электронной почты",
    },
    {
      id: 80,
      lang: "ru",
      termKey: "email",
      termValue: "Электронная почта",
    },
    {
      id: 81,
      lang: "ru",
      termKey: "invalid_wallet_address",
      termValue: "Недействительный адрес кошелька",
    },
    {
      id: 82,
      lang: "ru",
      termKey: "logging_in",
      termValue: "Вход в систему...",
    },
    {
      id: 83,
      lang: "ru",
      termKey: "login",
      termValue: "Войти",
    },
  ],

  jp: [
    // --- ConfirmationPopup.jsx ---
    {
      id: 1,
      lang: "jp",
      termKey: "confirmation_popup_title",
      termValue: "お客様各位",
    },
    {
      id: 2,
      lang: "jp",
      termKey: "confirmation_popup_message",
      termValue:
        "PaybisでUSDT (tron)を購入し、それが自動的にCoinsexchangeでUSDP (Playblock)ゲームトークンに交換され、プレイ可能になります。交換には数分かかる場合があり、Coinsexchangeから確認メールが届きます。",
    },
    {
      id: 3,
      lang: "jp",
      termKey: "confirmation_popup_continue_button",
      termValue: "OK、続行",
    },
    {
      id: 4,
      lang: "jp",
      termKey: "confirmation_popup_close_button",
      termValue: "閉じる",
    },

    // --- ExchangeRate.jsx ---
    {
      id: 5,
      lang: "jp",
      termKey: "input_group_you_send_label",
      termValue: "あなたが送信",
    },
    {
      id: 6,
      lang: "jp",
      termKey: "exchange_rate_label",
      termValue: "為替レート：1",
    },
    {
      id: 7,
      lang: "jp",
      termKey: "exchange_fee_label",
      termValue: "手数料",
    },
    {
      id: 8,
      lang: "jp",
      termKey: "funds_transferred_text",
      termValue: "資金は即座に転送されます",
    },
    {
      id: 9,
      lang: "jp",
      termKey: "exchange_continue_button",
      termValue: "続行",
    },

    // --- Footer.jsx ---
    {
      id: 10,
      lang: "jp",
      termKey: "footer_provided_by",
      termValue: "このサービスは提供されています",
    },

    // --- Header.jsx ---
    {
      id: 11,
      lang: "jp",
      termKey: "header_title_buy_usdp_0",
      termValue: "暗号通貨でチャージ",
    },
    {
      id: 12,
      lang: "jp",
      termKey: "header_title_buy_usdp_1",
      termValue: "金額",
    },
    {
      id: 13,
      lang: "jp",
      termKey: "header_title_buy_usdp_2",
      termValue: "受取人",
    },
    {
      id: 14,
      lang: "jp",
      termKey: "header_title_buy_usdp_3",
      termValue: "成功",
    },
    {
      id: 15,
      lang: "jp",
      termKey: "header_title_sell_usdp",
      termValue: "USDPを売る",
    },
    {
      id: 16,
      lang: "jp",
      termKey: "header_title_global_buy_usdp",
      termValue: "USDPを購入",
    },

    // --- IframePopup.jsx ---
    {
      id: 17,
      lang: "jp",
      termKey: "iframe_popup_close_button",
      termValue: "閉じる",
    },

    // --- InputGroup.jsx ---
    {
      id: 18,
      lang: "jp",
      termKey: "input_group_you_send_label",
      termValue: "あなたが送信",
    },
    {
      id: 19,
      lang: "jp",
      termKey: "input_group_enter_amount_placeholder",
      termValue: "金額を入力",
    },
    {
      id: 20,
      lang: "jp",
      termKey: "input_group_you_receive_label",
      termValue: "受け取る金額（概算） ≈",
    },

    // --- Page1.jsx ---
    {
      id: 21,
      lang: "jp",
      termKey: "page1_bridge_title",
      termValue: "0％手数料でブリッジ",
    },
    {
      id: 22,
      lang: "jp",
      termKey: "page1_error_message",
      termValue: "エラーが発生しました",
    },
    {
      id: 23,
      lang: "jp",
      termKey: "page1_loading",
      termValue: "読み込み中...",
    },

    // --- Page1Global.jsx ---
    {
      id: 24,
      lang: "jp",
      termKey: "page1_global_buy_bank_transfer",
      termValue: "銀行振込で購入",
    },
    {
      id: 25,
      lang: "jp",
      termKey: "page1_global_buy_card",
      termValue: "クレジット/デビットカードで購入",
    },
    {
      id: 26,
      lang: "jp",
      termKey: "page1_global_buy_button_icons",
      termValue: "クレジット/デビットカードのアイコン",
    },

    // --- Page3.jsx ---
    {
      id: 27,
      lang: "jp",
      termKey: "page3_send_currency_label_1",
      termValue: "送信",
    },
    {
      id: 28,
      lang: "jp",
      termKey: "page3_send_currency_label_2",
      termValue: "このアドレスに",
    },
    {
      id: 29,
      lang: "jp",
      termKey: "page3_recipient_address_label",
      termValue: "受取人アドレス",
    },
    {
      id: 30,
      lang: "jp",
      termKey: "page3_waiting_for_deposit",
      termValue: "入金を待っています",
    },

    // --- Page3Global.jsx ---
    {
      id: 31,
      lang: "jp",
      termKey: "page3_global_send_usdp_label",
      termValue: "{symbol}をこのアドレスに送信",
    },
    {
      id: 32,
      lang: "jp",
      termKey: "page3_global_recipient_address_label",
      termValue: "受取人アドレス",
    },
    {
      id: 33,
      lang: "jp",
      termKey: "page3_global_waiting_for_deposit",
      termValue: "入金を待っています",
    },
    {
      id: 34,
      lang: "jp",
      termKey: "page3_global_copy",
      termValue: "アドレスをコピー",
    },
    {
      id: 35,
      lang: "jp",
      termKey: "page3_global_copy_success",
      termValue: "コピー完了",
    },

    // --- Page4.jsx ---
    {
      id: 36,
      lang: "jp",
      termKey: "page4_transaction_arrived",
      termValue: "トランザクションが到着しました",
    },
    {
      id: 37,
      lang: "jp",
      termKey: "page4_exchanging",
      termValue: "交換中",
    },
    {
      id: 38,
      lang: "jp",
      termKey: "page4_transaction_complete",
      termValue: "トランザクションが完了しました",
    },
    {
      id: 39,
      lang: "jp",
      termKey: "page4_amount_sent",
      termValue: "ウォレットに送金されました",
    },
    {
      id: 40,
      lang: "jp",
      termKey: "page4_continue_button_loading",
      termValue: "トランザクション進行中",
    },
    {
      id: 41,
      lang: "jp",
      termKey: "page4_continue_button",
      termValue: "トランザクションが成功しました",
    },

    // --- Page4Global.jsx ---
    {
      id: 42,
      lang: "jp",
      termKey: "page4_global_transaction_in_progress",
      termValue: "トランザクション進行中",
    },
    {
      id: 43,
      lang: "jp",
      termKey: "page4_global_transaction_arrived",
      termValue: "トランザクションが到着しました",
    },
    {
      id: 44,
      lang: "jp",
      termKey: "page4_global_transaction_complete",
      termValue: "トランザクションが完了しました",
    },

    // --- QRContainer.jsx ---
    {
      id: 45,
      lang: "jp",
      termKey: "qrcontainer_send_only_1",
      termValue: "送信",
    },
    {
      id: 46,
      lang: "jp",
      termKey: "qrcontainer_send_only_2",
      termValue: "のみ",
    },

    // --- StatusBar.jsx ---
    {
      id: 47,
      lang: "jp",
      termKey: "statusbar_amount",
      termValue: "金額",
    },
    {
      id: 48,
      lang: "jp",
      termKey: "statusbar_recipient",
      termValue: "受取人",
    },
    {
      id: 49,
      lang: "jp",
      termKey: "statusbar_status",
      termValue: "ステータス",
    },

    // --- SwapTimer.jsx ---
    {
      id: 50,
      lang: "jp",
      termKey: "swaptimer_swap_time_info",
      termValue: "交換には数分かかることがあります。",
    },

    // --- CoinsExchange Project Specific Terms ---
    {
      id: 51,
      lang: "jp",
      termKey: "logout",
      termValue: "ログアウト",
    },
    {
      id: 52,
      lang: "jp",
      termKey: "selectBridge",
      termValue: "ブリッジを選択",
    },
    {
      id: 53,
      lang: "jp",
      termKey: "buyUSDPLabel",
      termValue: "USDPを購入",
    },
    {
      id: 54,
      lang: "jp",
      termKey: "sellUSDPLabel",
      termValue: "USDPを売る",
    },
    {
      id: 55,
      lang: "jp",
      termKey: "homeLabel",
      termValue: "ホーム",
    },
    {
      id: 56,
      lang: "jp",
      termKey: "aboutUsLabel",
      termValue: "私たちについて",
    },
    {
      id: 57,
      lang: "jp",
      termKey: "aboutUsSubtitle",
      termValue:
        "私たちは業界のリーディングカンパニーであり、お客様に最高のサービスと製品を提供することにコミットしています。",
    },
    {
      id: 58,
      lang: "jp",
      termKey: "ourValuesLabel",
      termValue: "私たちの価値観",
    },
    {
      id: 59,
      lang: "jp",
      termKey: "innovationLabel",
      termValue: "革新",
    },
    {
      id: 60,
      lang: "jp",
      termKey: "innovationDescription",
      termValue:
        "私たちは常にお客様と業界の変化するニーズに応えるために革新しています。",
    },
    {
      id: 61,
      lang: "jp",
      termKey: "integrityLabel",
      termValue: "誠実",
    },
    {
      id: 62,
      lang: "jp",
      termKey: "integrityDescription",
      termValue:
        "誠実さは私たちのビジネスの核心にあります。常に正しいことを信じています。",
    },
    {
      id: 63,
      lang: "jp",
      termKey: "customerFocusLabel",
      termValue: "顧客重視",
    },
    {
      id: 64,
      lang: "jp",
      termKey: "customerFocusDescription",
      termValue:
        "お客様は私たちの最優先事項です。優れたサービスとサポートを提供することに専念しています。",
    },

    // --- Reverse Bridge Project Specific Terms ---
    {
      id: 65,
      lang: "jp",
      termKey: "minWithdrawalText",
      termValue: "スワップ取引手数料をサポートするため、スワップする最",
    },
    {
      id: 66,
      lang: "jp",
      termKey: "networkFeesText",
      termValue: "小額は",
    },
    {
      id: 67,
      lang: "jp",
      termKey: "networkLabel",
      termValue: "ネットワーク",
    },
    {
      id: 68,
      lang: "jp",
      termKey: "senderAddressLabel",
      termValue: "送信元アドレス",
    },
    {
      id: 69,
      lang: "jp",
      termKey: "receiverAddressLabel",
      termValue: "受信者アドレス",
    },
    {
      id: 70,
      lang: "jp",
      termKey: "invalidAddressText",
      termValue: "無効なアドレス",
    },
    {
      id: 71,
      lang: "jp",
      termKey: "invalidText",
      termValue: "有効なアドレスを入力してください。",
    },
    {
      id: 72,
      lang: "jp",
      termKey: "page2_swap_info_text",
      termValue:
        "接続したウォレットからUSDPを送信していることを確認してください。他のウォレットから送信すると、資金が失われます。",
    },
    {
      id: 73,
      lang: "jp",
      termKey: "invalidAddressText_1",
      termValue: "アドレスステータス",
    },
    {
      id: 74,
      lang: "jp",
      termKey: "invalidAddressText_2",
      termValue: "有効",
    },
    {
      id: 75,
      lang: "jp",
      termKey: "invalidAddressText_3",
      termValue: "無効",
    },
    {
      id: 76,
      lang: "jp",
      termKey: "invalidAddressText_4",
      termValue: "上",
    },
    // More CoinsExchange Project Specific Terms
    {
      id: 77,
      lang: "jp",
      termKey: "page2_swap_info_text_1",
      termValue:
        "接続したウォレットからUSDPを送信していることを確認してください。他のウォレットから送信すると、資金が失われます。",
    },
    {
      id: 78,
      lang: "jp",
      termKey: "enter_details_to_log_in_and_continue",
      termValue: "ログインして続行するには詳細を入力してください",
    },
    {
      id: 79,
      lang: "jp",
      termKey: "enter_your_email",
      termValue: "メールアドレスを入力してください",
    },
    {
      id: 80,
      lang: "jp",
      termKey: "email",
      termValue: "メールアドレス",
    },
    {
      id: 81,
      lang: "jp",
      termKey: "invalid_wallet_address",
      termValue: "無効なウォレットアドレス",
    },
    {
      id: 82,
      lang: "jp",
      termKey: "logging_in",
      termValue: "ログイン中...",
    },
    {
      id: 83,
      lang: "jp",
      termKey: "login",
      termValue: "ログイン",
    },
  ],
};
