// src/components/BridgeSelection.js
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectBridge } from "../features/bridge/bridgeSlice";
import USDP_Buy from "../Images/USDP_Buy.png"; // Import the buy icon
import USDP_Sell from "../Images/USDP_Sell.png"; // Import the sell icon
import topLogo from "../Images/top_logo.png"; // Import the top logo
import footerLogo from "../Images/logo_com.png"; // Import the footer logo
import { selectTerms } from "../features/terms/termsSlice";

const BridgeSelection = () => {
  const dispatch = useDispatch();
  const terms = useSelector(selectTerms);

  const handleBridgeSelection = (bridge) => {
    dispatch(selectBridge(bridge));
  };

  return (
    <div className="bridge-selection">
      <header className="bridge-header">
        <h1>Coins Exchange</h1>
        <div
          className="top-logo"
          style={{ backgroundImage: `url(${topLogo})` }}
        />
      </header>
      <h2>
        {/* Select a Bridge */}
        {terms.selectBridge}
      </h2>
      <div className="buttons-container">
        <button onClick={() => handleBridgeSelection("Playnance Bridge Front")}>
          <img src={USDP_Buy} alt="Buy USDP" />
          <span>
            {/* Buy USDP */}
            {terms.buyUSDPLabel}
          </span>
        </button>
        <button
          onClick={() => handleBridgeSelection("Playnance Swap Bridge Front")}
        >
          <img src={USDP_Sell} alt="Sell USDP" />
          <span>
            {/* Sell USDP */}
            {terms.sellUSDPLabel}
          </span>
        </button>
      </div>
      <footer className="bridge-footer">
        <div
          className="footer-logo"
          style={{ backgroundImage: `url(${footerLogo})` }}
        />
      </footer>
    </div>
  );
};

export default BridgeSelection;
