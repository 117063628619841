// src/components/LanguageSelector.jsx
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../features/terms/termsSlice";
import { getLanguageTerms, processLanguageTerms } from "../utils/languageUtils";
import { TermsUtil } from "../utils/terms";

import enFlag from "../assets/flags/en.svg";
import ruFlag from "../assets/flags/ru.svg";
import jpFlag from "../assets/flags/jp.svg";
import krFlag from "../assets/flags/kr.svg";
import thFlag from "../assets/flags/th.svg";
import myFlag from "../assets/flags/my.svg";
import vnFlag from "../assets/flags/vn.svg";
import idFlag from "../assets/flags/id.svg";
import saFlag from "../assets/flags/sa.svg";
import brFlag from "../assets/flags/br.svg";
import deFlag from "../assets/flags/de.svg";
import frFlag from "../assets/flags/fr.svg";
import itFlag from "../assets/flags/it.svg";
import trFlag from "../assets/flags/tr.svg";
import esFlag from "../assets/flags/es.svg";
import plFlag from "../assets/flags/pl.svg";
import inFlag from "../assets/flags/in.svg";
import uaFlag from "../assets/flags/ua.svg";
import cnFlag from "../assets/flags/cn.svg";
import hkFlag from "../assets/flags/hk.svg";

const languages = [
  { code: "en", name: "English", flag: enFlag },
  { code: "ru", name: "Русский", flag: ruFlag },
  { code: "jp", name: "日本語", flag: jpFlag },
  { code: "ko", name: "한국어", flag: krFlag },
  { code: "th", name: "ภาษาไทย", flag: thFlag },
  { code: "ms", name: "Bahasa Melayu", flag: myFlag },
  { code: "vi", name: "tiếng việt", flag: vnFlag },
  { code: "id", name: "Bahasa Indonesia", flag: idFlag },
  { code: "ar", name: "العربية", flag: saFlag },
  { code: "pt", name: "português", flag: brFlag },
  { code: "de", name: "Deutsch", flag: deFlag },
  { code: "fr", name: "français", flag: frFlag },
  { code: "it", name: "italiano", flag: itFlag },
  { code: "tr", name: "Türkçe", flag: trFlag },
  { code: "es", name: "Español", flag: esFlag },
  { code: "pl", name: "Język polski", flag: plFlag },
  { code: "hi", name: "हिन्दी", flag: inFlag },
  { code: "uk", name: "Українська", flag: uaFlag },
  { code: "cn", name: "简体中文", flag: cnFlag },
  { code: "sc", name: "繁體中文", flag: hkFlag },  
];

const LanguageSelector = () => {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector((state) => state.terms.language);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const loadLanguageTerms = async () => {
      const currentLang = languages.find(
        (lang) => lang.code === selectedLanguage
      );
      if (currentLang) {
        try {
          const data = await getLanguageTerms(currentLang.code);
          const termsArray = await processLanguageTerms(data, currentLang.code);
          if (termsArray && termsArray.length > 0) {
            TermsUtil.setTerms(termsArray);
          }
        } catch (error) {
          console.error("Error fetching language terms:", error);
        }
      }
    };

    loadLanguageTerms();
  }, [selectedLanguage]);

  const handleLanguageChange = (lang) => {
    dispatch(setLanguage(lang.code));
    setIsOpen(false);
    // Removed the redundant call to TermsUtil.setTerms to prevent double dispatch
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="language-selector">
      <div className="selected-language" onClick={toggleDropdown}>
        <img
          src={languages.find((lang) => lang.code === selectedLanguage).flag}
          alt={selectedLanguage}
        />
        <span>
          {languages.find((lang) => lang.code === selectedLanguage).name}
        </span>
        <span className="arrow">▼</span>
      </div>
      {isOpen && (
        <div className="language-options">
          {languages.map((lang) => (
            <div key={lang.code} onClick={() => handleLanguageChange(lang)}>
              <img src={lang.flag} alt={lang.code} />
              <span>{lang.name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
