// src/store.js
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import authReducer from "./features/auth/authSlice";
import bridgeReducer from "./features/bridge/bridgeSlice";
import menuSlice from "./features/menu/menuSlice";
import termsReducer from "./features/terms/termsSlice";
import themeReducer from "./features/theme/themeSlice";
import viewReducer from "./features/view/viewSlice";

const reducers = combineReducers({
  theme: themeReducer,
  menu: menuSlice,
  bridge: bridgeReducer,
  auth: authReducer,
  view: viewReducer,
  terms: termsReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "terms", "theme"], // only auth, terms, and theme will be persisted
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
export default store;
